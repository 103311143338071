import { CompactEncrypt } from 'jose/jwe/compact/encrypt';
import { parseJwk } from 'jose/jwk/parse';
import { JWEHeaderParameters, JWK } from 'jose/types';

export async function generateJWE(
  payload: string,
  cryptoKeyObj: JWK,
  header: JWEHeaderParameters
) {
  const encoder = new TextEncoder();

  // prepare publicKey
  let publicKey = await parseJwk(cryptoKeyObj, header.alg);

  // create jwe
  let jwe = await new CompactEncrypt(encoder.encode(payload))
    .setProtectedHeader(header)
    .encrypt(publicKey);

  return jwe;
}
